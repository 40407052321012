export default {
  path: 'game',
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: 'win-lose',
      name: 'GameWinLose',
      component: () => import('@/views/game/GameWinLose')
    }, {
      path: 'txns',
      name: 'MemberTransactions',
      component: () => import('@/views/game/MemberTransactions')
    }, {
      path: 'credit',
      name: 'GameCredit',
      component: () => import('@/views/game/GameCredit')
    }, {
      path: 'member-credit',
      name: 'GameMemberCredits',
      component: () => import('@/views/game/MemberCredits')
    }, {
      path: 'activate',
      name: 'GameActivate',
      component: () => import('@/views/game/GameActivate')
    }, {
      path: 'sharing',
      name: 'GameSharing',
      component: () => import('@/views/game/GameSharing')
    }


    // {
    //   path: 'allowing',
    //   name: 'GameAllowSetting',
    //   component: () => import('@/views/game/GameAllowSetting')
    // }, {
    //   path: 'category',
    //   name: 'GameCategorySetting',
    //   component: () => import('@/views/game/GameCategorySetting')
    // }, {
    //   path: 'sharing',
    //   name: 'GameSharing',
    //   component: () => import('@/views/game/GameSharing')
    // }
  ]
}
