const AppConfig = {
  key: {
    LoggedIn: 'loggedInAgentRoselottokey',
    RefreshTokenKey: 'appRefreshTokenRoselottokey',
    TokenKey: 'appTokenRoselottokey',
    GlobalMarketKey: 'SelectedGlobalMarketRoselottokey'
  }
}

export default AppConfig;
